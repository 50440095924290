import "./SubscriptionShop.scss"
import Swiper from "react-id-swiper";
import {Setup} from "../../Services/Setup";
import {numberWithCommas, setAntdDrawerWidth, sleep, validation} from "../../Services/utils";
import plusProductIcon from "../PageV2/assets/plusProductIcon.svg";
import React, {useRef, useState, useEffect, Fragment} from "react";
import {listOptionLand_api} from "../../Services/Services"
import cartIcon from "./assets/cartIcon.svg"
import caretDownIcon from "./assets/caretDownIcon.svg"
import cartCheckedIcon from "./assets/cartCheckedIcon.png"
import {CloudUploadOutlined, LoadingOutlined, PictureOutlined,StarOutlined} from "@ant-design/icons"

import {Drawer, message, Spin, Switch} from "antd";
import {
    clearCart,
    decCart,
    getItemCount, getPayableAmount, getTotalDiscount,
    getTotalItems,
    getTotalPrice,
    incCart,
    syncCart
} from "../../Services/cartService";
import {useCart} from "../../Hook/useCart";
import {useDispatchCart} from "../../Hook/useDispatchCart";
import {
    finalizeBasketApi,
    sendOrderOtpApi,
    trackOrderApi,
    uploadReceiptLandOrderApi,
    uploadReceiptLandPlanApi
} from "../../Services/Services";
import {Collapse} from "react-collapse";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";
import useWindowDimensions from "../../Hook/useWindowDimensions";
import DumbbellLandingPageSubscriptionDrawer
    from "../DumbbellLandingPageSubscriptionDrawer/DumbbellLandingPageSubscriptionDrawer";


const SubscriptionCard = ({showDetailDrawer,page, p,slug, checkItemCount, handleAddItem,showPayDrawer, handleRemoveItem,showSubscriptionRegalDrawer}) => {



    return(
        <div></div>
    )
}

const ShopProductCard = ({showDetailDrawer, p, checkItemCount, handleAddItem, handleRemoveItem}) => {
    return (
        <div className="product-swiper-product-card">
            <div className="product-swiper-product-card-image" style={{width: '90%'}} onClick={e => {
                showDetailDrawer(p)
            }}>
                {p.image.length > 0 ?
                    <img src={Setup.filePoint + p.image} alt=""/> :
                    <div className="image-swiper-placeholder">
                        <PictureOutlined/>
                    </div>}
            </div>
            <div onClick={e => {
                showDetailDrawer(p)
            }}>
                <div className="product-swiper-product-card-price">
                    <span>{numberWithCommas(p.price - p.discount)}تومان</span>
                </div>

                <div className="product-swiper-product-card-discount">
                    {p.discount > 0 ? <span>{numberWithCommas(p.price)} تومان</span> : <span></span>}
                </div>


                <div className="product-swiper-product-card-name">
                    <span>{p.fa_name.length > 30 ? p.fa_name.substring(0, 30) + "..." : p.fa_name}</span>
                </div>
            </div>

            {p.stock > 0 && checkItemCount(p._id) < 1 ?
                <div className="product-swiper-product-card-add-btn clickable" onClick={e => {
                    handleAddItem(p)
                }}>
                    <img src={plusProductIcon} alt=""/>
                </div> : null
            }

            {p.stock < 1 ?
                <div className="out-of-stock">
                    <span>ناموجود</span>
                </div>
                : null}

            {p.stock > 0 && checkItemCount(p._id) > 0 ?
                <div className="add-remove-section">
                    <span className="add-section-btn clickable" onClick={e => handleAddItem(p)}>+</span>
                    <span className="count-section-btn">{checkItemCount(p._id)}</span>
                    <span className="remove-section-btn clickable" onClick={e => handleRemoveItem(p._id)}>-</span>
                </div>
                : null}

        </div>
    )
}

const SubscriptionShop = ({page,subscriptions,showSubscriptionRegalDrawer, slug}) => {
    const MyRef = useRef(null);
    const optionRef = useRef(null);



    const [subscriptionsIn,set_subscriptionsIn] = useState([])

    const paramsOptionLink = {
        slidesPerView: 'auto',
        spaceBetween: 2,
        // rebuildOnUpdate : true,
        rtl: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    }

    const paramsLink = {
        slidesPerView: 'auto',
        spaceBetween: 2,
        // rebuildOnUpdate : true,
        rtl: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        rebuildOnUpdate: true, // این خط را اضافه کنید
    }





    useEffect(e=>{
       getOptions()
        set_subscriptionsIn(subscriptions)
    },[])

    const [options,set_options] = useState([])
    const getOptions =async ()=>{
        try{
            const result = await listOptionLand_api(slug)
            set_options(result.data.data)
            if(result.data.data.length>0){
                handleClickOnOption({title:"allx",_id:"allxId"})
            }
        }catch (e) {
            console.log(e)
        }
    }

    //plans drawer
    const [visibleTraineeDrawer,set_visibleTraineeDrawer] = useState(false)
    const [selectedSubs,set_selectedSubs] = useState({})
    const [selectedOption,set_selectedOption] = useState({})

    const showPayDrawer = (p)=>{
        set_selectedSubs(p)
        set_visibleTraineeDrawer(true)
    }

    const onPayDrawerClose = () => {
        set_visibleTraineeDrawer(false);
    };

    const handleClickOnOption =async (option)=>{
        set_selectedOption(option)
        if(option.title==="allx"){
            set_subscriptionsIn(subscriptions)
        }
        else {
            let res = []
            subscriptions.forEach(s=>{
                s.options.forEach(o=>{
                    if(o._id===option._id){
                        res.push(s)
                    }
                })
            })
            // set_subscriptionsIn([])
            // await sleep(0)
            set_subscriptionsIn(res)
        }
    }

    return (
        <div className="online-shop-container">
            {options.length>0?<div className="option-regal">
                <div className="swiper-page-wrapper">
                    <Swiper ref={optionRef}  {...paramsOptionLink}>
                        <div className="option-card-swiper-product-card-wrapper" >
                            <div
                                style={{backgroundColor:selectedOption._id==="allxId" &&
                                    page.tertiary_color?.length>0?page?.tertiary_color:""
                                }}
                                className={selectedOption._id==="allxId"?
                                "option-card-swiper-product-card active-option":
                                "option-card-swiper-product-card"
                            } onClick={e=>{
                                handleClickOnOption({title:"allx",_id:"allxId"})
                            }}>
                                <span>همه</span>
                            </div>
                        </div>
                        {options.map(o=>{
                            return(
                                <div className="option-card-swiper-product-card-wrapper" >
                                    <div
                                        style={{backgroundColor:selectedOption._id===o._id &&
                                            page.tertiary_color?.length>0?page.tertiary_color:""
                                        }}
                                        className={selectedOption._id===o._id?
                                        "option-card-swiper-product-card active-option":
                                        "option-card-swiper-product-card"
                                    } onClick={e=>{
                                        handleClickOnOption(o)
                                    }}>
                                        <span>{o.title}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </Swiper>
                </div>
            </div>:null}
            {subscriptions.length>0 && subscriptionsIn.length<1?
            <div className="subscription-swiper-wrapper"></div>
                :null}
            {subscriptionsIn.length > 0 ?
                <div className="swiper-page-wrapper ">

                    <Swiper ref={MyRef}  {...paramsLink}>
                        {subscriptionsIn.map((p, i) => {
                            return (
                                <div className="subscription-card-swiper-product-card-wrapper" >
                                    {p.total_campaign_discount>0?
                                        <div className="discount-badge">
                                            <span><StarOutlined />{`جشنواره`}</span>
                                        </div>:null
                                    }



                                    <div className="subscription-card-swiper-product-card" onClick={e=>showPayDrawer(p)}>
                                        <div className="subscription-card-swiper-product-card-image" >
                                            <div className="image-mask">
                                                {p.title}
                                            </div>
                                            {p.image.length > 0 ?
                                                <img src={Setup.filePoint + p.image} alt=""/> :
                                                <div className="subscription-card-image-swiper-placeholder">
                                                    <PictureOutlined/>
                                                </div>}
                                        </div>


                                        <div className="subscription-card-list">

                                            {p.group_camp?.amount?
                                                <div className="group-buy">
                                                    <span className="label-text">{`اگه ${p.group_camp?.min_person_count} نفری ثبت نام کنید هر نفر ${numberWithCommas(p.group_camp?.amount)} تومان تخفیف میگیره !  `}</span>
                                                </div>:null
                                            }

                                            {p?.description.map(d=>{
                                                return(
                                                    <div className="subscription-card-list-item">
                                                        <span
                                                            style={{backgroundColor: page.primary_color?.length>0?page?.primary_color:""
                                                            }}
                                                            className="bullet"></span>


                                                        <span className="label-text">{d.text}</span>
                                                    </div>
                                                )
                                            })}

                                        </div>

                                        <div className="price-footer">

                                            <div className="subscription-card-label">
                                                <span>{p.label}</span>
                                            </div>

                                            <div >

                                                <div className="subscription-card-swiper-product-card-discount">
                                                    {p.discount > 0 ? <span>{numberWithCommas(p.price)} تومان</span> :
                                                        <span></span>}
                                                </div>

                                                {/*<h1>{p.discount}</h1>*/}
                                                <div className="subscription-card-swiper-product-card-price">
                                                    <span>{numberWithCommas(p.price - p.discount.toFixed()<0?"0":p.price - p.discount.toFixed())}تومان</span>
                                                </div>

                                            </div>
                                            </div>





                                        <div
                                            className="subscription-card-btn">
                                            <span style={{backgroundColor: page.secondary_color?.length>0?page.secondary_color:""
                                            }}>خرید اشتراک</span>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                        <div className="product-swiper-product-card-wrapper"></div>
                    </Swiper>

                </div> : null
            }

            <Drawer destroyOnClose={true} bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onPayDrawerClose} visible={visibleTraineeDrawer}>
                <DumbbellLandingPageSubscriptionDrawer plan={selectedSubs} slug={slug} page={page} trainerName={page.full_name} handleDoneAddTrainee={e=>{
                    console.log("done")
                }} />
            </Drawer>
        </div>
    )
}

export default SubscriptionShop;
