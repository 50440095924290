import React, {useEffect, useRef, useState} from "react";
import Swiper from "react-id-swiper";
import emptyIcon from "../../assets/dumbbell_image/V2/empty_icon_v2.svg";
import {getLandApi, getWidgetLandApi, listOptionLand_api, listSubscriptionLand_api} from "../../Services/Services";
import {useParams} from "react-router";
import telegramImage from "../../assets/dumbbell_image/V2/social_telegram.svg";
import telegramImageOff from "../../assets/dumbbell_image/V2/social_telegram_off.svg";
import youTubeImage from "../../assets/dumbbell_image/V2/social_youtube.svg";
import youTubeImageOff from "../../assets/dumbbell_image/V2/social_youTube_off.svg";
import instagramImage from "../../assets/dumbbell_image/V2/social_instagram.svg";
import instagramImageOff from "../../assets/dumbbell_image/V2/social_instagram_off.svg";
import whatsAppImage from "../../assets/dumbbell_image/V2/social_whatsApp.svg";
import whatsAppImageOff from "../../assets/dumbbell_image/V2/social_whatsApp_off.svg";
import SubscriptionCard from "./SubscriptionCard";
import {Spin} from "antd";

function SubscriptionWidget(){

    const {slug} = useParams()
    const [page,set_page] = useState(null)
    const [spin,set_spin] = useState(false)
    const [selectedSubscription, set_selectedSubscription] = useState({})
    const [subscriptions, set_subscriptions] = useState([])
    const [subscriptionsIn,set_subscriptionsIn] = useState()
    const [options,set_options] = useState([])
    const [selectedOption,set_selectedOption] = useState({})
    const [subscriptionTitle, set_subscriptionTitle] = useState("")
    const [subscriptionStatus, set_subscriptionStatus] = useState(false)
    const optionRef = useRef(null);
    const paramsOptionLink = {
        slidesPerView: 'auto',
        spaceBetween: 2,
        // rebuildOnUpdate : true,
        rtl: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    }

    // useEffect(() => {
    //     handleClickOnOption({title:"allx",_id:"allxId"})
    // }, [subscriptionsIn]);
    const getLandSubscriptions = async ()=>{
        set_spin(true)
        try{
            const response = await listSubscriptionLand_api(slug)
            set_subscriptions(response.data.data)
            set_subscriptionsIn(response.data.data)
            set_spin(false)
        }catch(e){
            set_spin(false)
            console.log(e)
        }
    }


    const fillPage =async (p)=>{
        set_subscriptionTitle(p.subscription_title)
        set_subscriptionStatus(p.subscription_status)

        if(p.subscription_status){
            getLandSubscriptions()
        }
    }


    const getLand =async ()=>{
        try{
            const resp = await getWidgetLandApi(slug)
            set_page(resp.data.data)
            fillPage(resp.data.data)
        }catch (e) {
            console.log(e)
        }
    }

    const getOptions =async ()=>{
        try{
            const result = await listOptionLand_api(slug)
            set_options(result.data.data)
            if(result.data.data.length>0){
                handleClickOnOption({title:"allx",_id:"allxId"})
            }
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(e=>{
        getOptions()
        getLand()
    },[])

    const handleClickOnOption =async (option)=>{
        set_selectedOption(option)
        if(option.title==="allx"){
            set_subscriptionsIn(subscriptions)
        }
        else {
            let res = []
            subscriptions.forEach(s=>{
                s.options.forEach(o=>{
                    if(o._id===option._id){
                        res.push(s)
                    }
                })
            })
            set_subscriptionsIn(res)
        }
    }


    return(
      <>
          <div className="subs-drawer-header">
              {subscriptionTitle.length>0? <span>اشتراک های ویژه</span>:null}

          </div>
          {options.length>0?<div className="option-regal">
              <div className="swiper-page-wrapper">
                  <Swiper ref={optionRef}  {...paramsOptionLink}>
                      <div className="option-card-swiper-product-card-wrapper" >
                          <div
                              style={{backgroundColor:selectedOption._id==="allxId" &&
                                  page?.tertiary_color?.length>0?page?.tertiary_color:""
                              }}
                              className={selectedOption._id==="allxId"?
                                  "option-card-swiper-product-card active-option":
                                  "option-card-swiper-product-card"
                              } onClick={e=>{
                              handleClickOnOption({title:"allx",_id:"allxId"})
                          }}>
                              <span>همه</span>
                          </div>
                      </div>
                      {options.map(o=>{
                          return(
                              <div className="option-card-swiper-product-card-wrapper" >
                                  <div
                                      style={{backgroundColor:selectedOption._id===o._id &&
                                          page?.tertiary_color?.length>0?page?.tertiary_color:""
                                      }}
                                      className={selectedOption._id===o._id?
                                          "option-card-swiper-product-card active-option":
                                          "option-card-swiper-product-card"
                                      } onClick={e=>{
                                      handleClickOnOption(o)
                                  }}>
                                      <span>{o.title}</span>
                                  </div>
                              </div>
                          )
                      })}
                  </Swiper>
              </div>
          </div>:null}


          <Spin spinning={spin}>
              <div className="package-list">
                  {subscriptionsIn?.map(d=>{
                      return(
                          <SubscriptionCard  page={page} item={d} slug={slug}/>
                      )
                  })}
              </div>
          </Spin>
      </>
)
}

export default SubscriptionWidget;