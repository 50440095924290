import moment from "moment-jalaali";

const Regex ={
    // mobile : /^(\+98|0)?9\d{9}$/,
    verify_code :/^[0-9]{4}$/,
    number :/^[0-9]$/,
    mobile :/^09[0|1|2|3][0-9]{8}$/,
    email:/^[^\s@]+@[^\s@]+\.[^\s@]+$/
}

export const validation = (type,val)=>{

    if(type === "MOBILE"){
        return Regex.mobile.test(val);
    }

    if(type === "NUMBER"){
        return Regex.number.test(val);
    }

    if(type === "EMAIL"){
        return Regex.email.test(val);
    }

    if(type === "VERIFY_TOKEN"){
        return Regex.verify_code.test(val);
    }

}


export const setTokenToLocalStorage = (token)=>{
    localStorage.setItem("token",token)
}
export const setUserToLocalStorage = (user)=>{
    localStorage.setItem("user",JSON.stringify(user))
}

export const clearLocalStorage = ()=>{
    localStorage.clear()
}

export function numberWithCommas  (x) {
    //
    // console.log("X ",x)

    // r= r.toString()
    //
    // r= r.replaceAll(",", "");
    //
    // console.log("X ",r)

    //return x

    let r =""
    if(x){
        r = x.toString()
        r= r.replaceAll(",", "");
        return r.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return ""

}

export function numberWithCommasString  (x) {
    //
    // console.log("X ",x)

    // r= r.toString()
    //
    // r= r.replaceAll(",", "");
    //
    // console.log("X ",r)

    //return x

    let r =""
    if(x){
        r = x.toString()
        r= r.replaceAll(",", "");
        return r.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return ""

}

export const convertMonthNumberToName = (num)=>{
    switch (num) {
        case "01":
            return "فرودرین"
            break;
        case "02":
            return "اردیبهشت"
            break;
        case "03":
            return "خرداد"
            break;
        case "04":
            return "تیر"
            break;
        case "05":
            return "مرداد"
            break;
        case "06":
            return "شهریور"
            break;
        case "07":
            return "مهر"
            break;
        case "08":
            return "آبان"
            break;
        case "09":
            return "آذر"
            break;
        case "10":
            return "دی"
            break;
        case "11":
            return "بهمن"
            break;
        case "12":
            return "اسفند"
            break;

    }

}


export const convertToEnglishNumbers = (value) => {
    const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
    const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

    let convertedValue = value;

    persianNumbers.forEach((num, index) => {
        console.log("val",convertedValue)
        convertedValue = convertedValue.replace(num, index);
    });

    arabicNumbers.forEach((num, index) => {
        convertedValue = convertedValue.replace(num, index);
    });

    return convertedValue.replace(/[^0-9]/g, '');
};


export function englishNumber(string) {
    return string.split('۰').join('0')
        .split('۱').join('1')
        .split('۲').join('2')
        .split('۳').join('3')
        .split('۴').join('4')
        .split('۵').join('5')
        .split('۶').join('6')
        .split('۷').join('7')
        .split('۸').join('8')
        .split('۹').join('9')
}


function persianNumber(string) {
    return string.split('0').join('۰')
        .split('1').join('۱')
        .split('2').join('۲')
        .split('3').join('۳')
        .split('4').join('۴')
        .split('5').join('۵')
        .split('6').join('۶')
        .split('7').join('۷')
        .split('8').join('۸')
        .split('9').join('۹')
}


export const convertMonthIntNumberToName = (num)=>{
    switch (num) {
        case 1:
            return "فرودرین"
            break;
        case 2:
            return "اردیبهشت"
            break;
        case 3:
            return "خرداد"
            break;
        case 4:
            return "تیر"
            break;
        case 5:
            return "مرداد"
            break;
        case 6:
            return "شهریور"
            break;
        case 7:
            return "مهر"
            break;
        case 8:
            return "آبان"
            break;
        case 9:
            return "آذر"
            break;
        case 10:
            return "دی"
            break;
        case 11:
            return "بهمن"
            break;
        case 12:
            return "اسفند"
            break;

    }

}



export const checkPlanEndDate = (plan)=>{
    let supportDay = plan.support_day;
    let deliveredAt = plan.deliveredAt;
    if(!deliveredAt)
        return false
    let end= moment(deliveredAt).add(supportDay,'days').unix();
    let now = parseInt((new Date().getTime()/1000).toFixed())
    return (end-now)<=0
}


// export const checkPlanEndDate = (plan)=>{
//     let duration = plan.duration;
//     let startAt = plan.startAt; // 1403/03/11
//     if(!startAt)
//         return false
//
//     let end_date = moment(startAt,"jYYYY/jMM/jDD").add(duration,'days');
//     let end = moment(end_date).unix()
//     let now = parseInt((new Date().getTime()/1000).toFixed())
//     return (end-now)<=0
// }

export const checkFileSize = (size,limit)=>{
    let fileSize = Math.floor(size/1000000)
    return fileSize<=limit
}


export const  setAntdDrawerWidth =()=>{
    // let mobile_sec = document.getElementById('mobile-sec').getBoundingClientRect()
    // let mobile_sec_width=mobile_sec.width
    //
    // let drawer_elm = document.getElementsByClassName('ant-drawer-content')
    // let drawer_mask_elm = document.getElementsByClassName('ant-drawer-content-wrapper')
    //
    // if(drawer_elm.length>0){
    //     drawer_mask_elm[0].style.direction=`rtl`
    //     drawer_elm[0].style.width=`${mobile_sec_width}px`
    // }
    //
    // else
    //     console.log("not exist",mobile_sec_width)
}

export const sleep = (seconds) => {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
};


export const convertCampaignType = (t) => {
    switch (t) {
        case 'after_buy_discount_code':
            return "بعد از خرید - کد تخفیف"
            break;
        case 'before_buy':
            return "قبل از خرید"
            break;
        default:
            return ""
    }
};


export const calcCampaignPriceAndDiscount = (subscriptions) => {
    return subscriptions.map(subscription => {
        let subscription_discount = subscription.discount; // شروع از تخفیف اولیه
        let campDiscount = 0; // شروع از تخفیف کمپین

        // محاسبه قیمت نهایی اشتراک پس از تخفیف اولیه
        let finalPriceAfterInitialDiscount = subscription.price - subscription_discount;

        // اگر اشتراک شامل جشنواره باشد، تغییرات لازم را اعمال می‌کنیم
        if (subscription.campaigns && subscription.campaigns.length > 0) {
            subscription.campaigns.forEach(campaign => {
                // اگر نوع تخفیف درصدی باشد
                if (campaign.discount_type === 'percent') {
                    campDiscount += (finalPriceAfterInitialDiscount * campaign.discount_value) / 100;
                } else if (campaign.discount_type === 'amount') {
                    campDiscount += campaign.discount_value;
                }
            });
        }

        let finalDiscount = subscription_discount + campDiscount; // تخفیف نهایی با تخفیف‌های جشنواره

        // به روز رسانی اشتراک با تخفیف جدید و اضافه کردن تخفیف جشنواره
        return {
            ...subscription,
            discount: finalDiscount,
            campaign_discount: campDiscount
        };
    });
};

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}