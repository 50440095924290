import React, {Fragment, useEffect, useRef, useState} from 'react';
import "./DumbbellPlanCreateTrainee.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import sampleFace from "../../assets/images/default_user.png";
import capsuleIcon from "../../assets/dumbbell_image/capsule-icon.png";
import dumbbellIcon from "../../assets/dumbbell_image/dumbbell-icon.png";
import foodIcon from "../../assets/dumbbell_image/food-icon.png";
import micIcon from "../../assets/dumbbell_image/mic-icon.png";
import addPlayIcon from "../../assets/dumbbell_image/V2/add_play_icon.svg";
import notPlayIcon from "../../assets/dumbbell_image/V2/not_play.svg";
import weekOnIcon from "../../assets/dumbbell_image/V2/week_on_icon.svg";
import clockIcon from "../../assets/dumbbell_image/V2/clock_icon.svg";
import printIcon from "../../assets/dumbbell_image/V2/print_icon.svg";
import libraryIcon from "../../assets/dumbbell_image/V2/library_icon.svg";
import plusBlueIcon from "../../assets/dumbbell_image/plus-blue-icon.png";
import trashIcon from "../../assets/dumbbell_image/trash-icon.png";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import addCalendarIcon from "../../assets/dumbbell_image/V2/add_calendar_icon.svg";
import addWeekOnIcon from "../../assets/dumbbell_image/V2/add_week_on_icon.svg";
import addWeekIcon from "../../assets/dumbbell_image/V2/add_week_icon.svg";
import addCompIcon from "../../assets/dumbbell_image/V2/add_comp_icon.svg";
import videoWhiteIcon from "../../assets/dumbbell_image/V2/video_white_icon.svg";

import addTrashIcon from "../../assets/dumbbell_image/V2/add_trash_icon.svg";
import addMergeIcon from "../../assets/dumbbell_image/V2/add_merge_icon.svg";
import addMergeOnIcon from "../../assets/dumbbell_image/V2/add_merge_on_icon.svg";
import addSuperIcon from "../../assets/dumbbell_image/V2/add_super_icon.svg";
import addSuperOnIcon from "../../assets/dumbbell_image/V2/add_super_on_icon.svg";



import editIcon from "../../assets/dumbbell_image/edit-icon.png";
import playIcon from "../../assets/dumbbell_image/play-icon.png";
import Swiper from "react-id-swiper";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css';
import { v4 as uuidv4 } from 'uuid';
import {Setup} from "../../Services/Setup";
import { CloseCircleOutlined,CompressOutlined,CodeSandboxOutlined ,UserOutlined,CloudUploadOutlined,SkypeOutlined,CloseOutlined,LinkOutlined,CameraOutlined} from '@ant-design/icons';
import {Drawer, message, Popconfirm, Spin, Switch} from "antd";
import moment from "moment-jalaali";
import {useParams} from "react-router";
import ReactPlayer from 'react-player'
import {
    changePlanModeApi,
    deleteLibItem_api, deleteVideo_api,
    getLib_api, getMyProfileApi, getTraineeApi,
    mediaChallengeDeleteApi,
    mediaDeleteApi,
    mediaRedDeleteApi, myGuestPlanOneApi, myPlanOneApi,
    planOneApi, saveAndSendPlanApi,
    savePlanApi, searchVideo_api, videoUploadApi
} from "../../Services/Services";
import {checkPlanEndDate, convertMonthIntNumberToName} from "../../Services/utils";
import DumbbellTrainerMenu from "../DumbbellTrainerMenu/DumbbellTrainerMenu";
import {useDispatchError} from "../../Hook/useDispatchError";
import useLongPress from "../../Hook/useLongPress";
import {useFocus} from "../../Hook/useFocus";
import messageIcon from "../../assets/dumbbell_image/V2/chat_icon.svg"
import DumbbellRequestPlan from "../DumbbellRequestPlan/DumbbellRequestPlan";
import DumbbellBoxTrainer from "../DumbbellBoxTrainer/DumbbellBoxTrainer";
import DumbbellChatTrainer from "../DumbbellChatTrainer/DumbbellChatTrainer";
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import picIcon from "../../assets/dumbbell_image/V2/pic_icon.svg";
import profileIcon from "../../assets/dumbbell_image/V2/profile_icon.svg";
import DumbbellVideoList from "../DumbbellVideoList/DumbbellVideoList";
import DumbbellChatTrainee from "../DumbbellChatTrainee/DumbbellChatTrainee";
import DumbbellBoxTrainee from "../DumbbellBoxTrainee/DumbbellBoxTrainee";
import DumbbellHederTrainee from "../../Components/DumbbellHederTrainee/DumbbellHederTrainee";
let globalFilter = "all"
let deleteFlag = false

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}


function transformToWeek (times){
    let customWeek = null
    let complete = false
    times.forEach((t,index)=>{
        if(!complete && t.weekDay==="شنبه"){
            customWeek = times.slice(index,index+7)
            complete =true
        }
    })
    return customWeek
}

function calcTimes (times,planMode){
    if(planMode==="days")
        return times

    if(planMode==="week"){
        console.log("week : ",times)
        return transformToWeek(times)
    }

}

const params = {
    slidesPerView: 'auto',
    spaceBetween: 5,
    // rebuildOnUpdate : true,
    rtl:true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    }
}

function SuperHeaderCard({part,breakSuperSet,changeSuperItems,supers}){

    const [superItems,set_superItems] = useState(null)

    //super rest
    const [visibleRestDrawer,set_visibleRestDrawer] = useState(false)
    const onRestDrawerClose = ()=>{
        set_visibleRestDrawer(false)
    }
    const showRestDrawer = ()=>{
        set_visibleRestDrawer(true)
    }


    const handleSelectItems  =(type,val)=>{
        changeSuperItems(part,type,val)
        onRepeatDrawerClose()
        onRestDrawerClose()
    }

    //super repeat
    const [visibleRepeatDrawer,set_visibleRepeatDrawer] = useState(false)
    const onRepeatDrawerClose = ()=>{
        set_visibleRepeatDrawer(false)
    }
    const showRepeatDrawer = ()=>{
        set_visibleRepeatDrawer(true)
    }

    useEffect(()=>{
        let superIt = null
        supers.forEach(s=>{
            s.others.forEach(o=>{
                if(o.id===part.id){
                    superIt = s
                }
            })
        })

        if(superIt){
            console.log("Super : ",superIt)
            set_superItems(superIt)
        }



    },[])

    return(
        <>
            <div className="plan-card-v2-super">
                <div className="plan-exercise-card-main">
                    <div className="plan-exercise-card-super-header">
                        <div className="plan-exercise-card-super-actions">
                            {/*<div className="plan-exercise-card-super-actions-item clickable" onClick={e=>{*/}
                            {/*    breakSuperSet(part)*/}
                            {/*}}>*/}
                            {/*    <CompressOutlined/>*/}
                            {/*</div>*/}

                        </div>
                        {superItems?.items?
                            <div className="plan-exercise-card-main-body-child">
                                <div className="plan-exercise-card-main-body-child-item" onClick={e=>{
                                    showRepeatDrawer(part)
                                }}>
                                    <span className="plan-exercise-card-main-body-child-item-val">{superItems?.items[1].value}</span>
                                    <span className="plan-exercise-card-main-body-child-item-label">تکرار</span>
                                </div>
                                <div className="plan-exercise-card-main-body-child-item" onClick={e=>{
                                    showRestDrawer(part)
                                }}>
                                    <span className="plan-exercise-card-main-body-child-item-val">{superItems?.items[3].value}</span>
                                    <span className="plan-exercise-card-main-body-child-item-label">استراحت(ثانیه)</span>
                                </div>
                            </div>:null
                        }

                        <div className="plan-exercise-card-main-header-super-name" >
                            <span>سوپر ست</span>
                        </div>

                    </div>

                </div>

                <div className={"plan-card-number-super"}>
                    <span className="super-bullet"></span>
                    <div className={"plan-card-number-line-super"}></div>
                </div>

            </div>


        </>

    )
}


function SuppPartCard({plan,
                          supp,
                          alternatives,
                          set_supp,
                          breakFromLink,
                          setAlterToRoot,
                          handleSelectPartV2,
                          selectPartv2,
                          currentSection,currentTimes,part,
                          handleDeletePartItems,
                          showHourDrawer,
                          setInputFocus,
                          set_selectedPart,
                          selectedPart,handleClickDeletePart}){


    const addToMeals = (p)=>{
        let find =  supp.find(l=>l.id===p.id)
        if(find)
            set_supp(supp.filter(l=>l.id!==p.id))
        else
            set_supp([...supp,p])
    }

    const existInMeals = (p)=>{
        return supp.find(l=>l.id===p.id)
    }

    const [visible,set_visible] = useState(false)
    const [myAlters,set_myAlters] = useState([])

    useEffect(()=>{
        let hasAlter = false
        let isAlterRoot = false
        set_myAlters([])
        set_visible(false)

        alternatives.forEach(a=>{
            if(part.id===a.root.id){
                isAlterRoot =true;
                set_myAlters(a.others)
            }
            a.others.forEach(o=>{
                if(part.id===o.id)
                    hasAlter =true
            })
        })


        if(hasAlter){
            if(isAlterRoot)
                set_visible(true)
            else
                set_visible(false)
        }else {
            set_visible(true)
        }

    },[alternatives])

    return(
        <Fragment>
            {visible?

               <>
               <div className="plan-card-v2">

                            <div className="plan-exercise-card-main-header-alternatives">
                       {myAlters.map((a,index)=>{
                           return(
                               <div onClick={e=>{
                                   setAlterToRoot(part,a)
                               }} className={part.id===a.id?"plan-exercise-card-main-header-alternatives-item active-alter clickable":"plan-exercise-card-main-header-alternatives-item clickable"}>
                                   <span>{a.order}</span>
                               </div>
                           )
                       })}
                   </div>


                   <div className="queue-sec">
                           <div style={selectPartv2.id===part.id?{border:"2px solid #f58f90"}:{}} className={"queue-number"} onClick={e=>{
                               handleSelectPartV2(part)
                           }}
                           >
                           <span>{part.order}</span>
                       </div>
                   </div>



                   {!part.desc_mode?
                   <div
                       onClick={e=>{
                           console.log(part.id)
                           setInputFocus()
                           set_selectedPart(part.id)
                           handleSelectPartV2(part)
                           // showHourDrawer(part)
                       }}
                       className={selectPartv2===part.id?"card-main-header-v2":"card-main-header-v2"}>


                       <div className="plan-exercise-card-main-header-name" >
                           <span style={{margin:"0 4px"}}>{part.text}</span>
                           <img src={clockIcon} alt=""/>
                       </div>
                   </div>:null}

                   {part.desc_mode?
                       <div className="meal-desc">
                           <h5>توضیحات : </h5>
                           <span>{part.text}</span>
                       </div>:null}

                   {!part.desc_mode?
                   <div className="plan-exercise-card-main-body">
                       <div className="plan-exercise-card-main-body-childes">
                           {plan[currentSection]?.times[currentTimes]?.parts.find(p=>p.id===part.id).items.map(i=>{
                                   return(
                                       <div className="plan-exercise-card-main-body-child bullet-sec">
                                           <span>{i.text}</span>
                                           <i className="bullet"></i>
                                       </div>
                                   )
                               }

                           )}
                       </div>
                   </div>:null}


               </div>







                   {/*<div className="plan-card-diet">*/}
                   {/*    <div className="plan-exercise-card-main">*/}


                   {/*        <div className="plan-exercise-card-main-header-alternatives">*/}
                   {/*            {myAlters.map((a,index)=>{*/}
                   {/*                return(*/}
                   {/*                    <div onClick={e=>{*/}
                   {/*                        setAlterToRoot(part,a)*/}
                   {/*                    }} className={part.id===a.id?"plan-exercise-card-main-header-alternatives-item active-alter clickable":"plan-exercise-card-main-header-alternatives-item clickable"}>*/}
                   {/*                        <span>{index+1}</span>*/}
                   {/*                    </div>*/}
                   {/*                )*/}
                   {/*            })}*/}
                   {/*        </div>*/}

                   {/*        <div*/}
                   {/*            onClick={e=>{*/}
                   {/*                console.log(part.id)*/}
                   {/*                setInputFocus()*/}
                   {/*                set_selectedPart(part.id)*/}
                   {/*            }}*/}
                   {/*            className={selectedPart===part.id?"plan-exercise-card-main-header active-part":"plan-exercise-card-main-header"}>*/}

                   {/*            <div className="plan-exercise-card-main-header-actions">*/}

                   {/*                <div className="plan-exercise-card-main-header-delete" onClick={e=>{*/}
                   {/*                    handleClickDeletePart(part)*/}
                   {/*                }}>*/}
                   {/*                    <img src={trashIcon} alt=""/>*/}
                   {/*                </div>*/}

                   {/*                {myAlters.length<1?<div className={existInMeals(part)?"plan-exercise-card-main-header-link link-on":"plan-exercise-card-main-header-link"} onClick={e=>{*/}
                   {/*                    addToMeals(part)*/}
                   {/*                }}>*/}
                   {/*                    <LinkOutlined />*/}
                   {/*                </div>:null}*/}


                   {/*                { myAlters.length>1?<div onClick={e=>{*/}
                   {/*                    breakFromLink(part)*/}
                   {/*                }}>*/}
                   {/*                    <CompressOutlined />*/}
                   {/*                </div>:null}*/}

                   {/*            </div>*/}



                   {/*            <div className="plan-exercise-card-main-header-name" onClick={e=>{*/}
                   {/*                showHourDrawer(part)*/}
                   {/*            }}>*/}
                   {/*                <span>{part.text}</span>*/}
                   {/*            </div>*/}
                   {/*        </div>*/}

                   {/*        <div className="plan-exercise-card-main-body">*/}
                   {/*            <div className="plan-exercise-card-main-body-childes">*/}
                   {/*                {plan[currentSection]?.times[currentTimes]?.parts[part.id-1]?.items.map(i=>{*/}
                   {/*                        return(*/}
                   {/*                            <div className="plan-exercise-card-main-body-child">*/}
                   {/*                                <span>{i.text}</span>*/}
                   {/*                                <img onClick={e=>{*/}
                   {/*                                    handleDeletePartItems(part,i)*/}
                   {/*                                }} src={trashWhiteIcon} alt=""/>*/}
                   {/*                            </div>*/}
                   {/*                        )*/}
                   {/*                    }*/}

                   {/*                )}*/}
                   {/*            </div>*/}
                   {/*        </div>*/}

                   {/*    </div>*/}

                   {/*    <div className="plan-card-number">*/}
                   {/*        <span>{part.order}</span>*/}

                   {/*    </div>*/}

                   {/*</div>*/}
               </>

                :null}
        </Fragment>
    )
}



function MealPartCard({plan,
                          meals,
                          alternatives,
                          set_meals,
                          handleSelectPartV2,
                          selectPartv2,
                          breakFromLink,
                          setAlterToRoot,
                          currentSection,currentTimes,part,handleDeletePartItems,showHourDrawer,setInputFocus,set_selectedPart,selectedPart,handleClickDeletePart}){


    const addToMeals = (p)=>{
        let find =  meals.find(l=>l.id===p.id)
        if(find)
            set_meals(meals.filter(l=>l.id!==p.id))
        else
            set_meals([...meals,p])
    }

    const existInMeals = (p)=>{
        return meals.find(l=>l.id===p.id)
    }

    const [visible,set_visible] = useState(false)
    const [myAlters,set_myAlters] = useState([])

    useEffect(()=>{
        console.log("yyyy 8889")
        let hasAlter = false
        let isAlterRoot = false
        set_myAlters([])
        set_visible(false)

        alternatives.forEach(a=>{
            if(part.id===a.root.id){
                isAlterRoot =true;
                set_myAlters(a.others)
            }
            a.others.forEach(o=>{
                if(part.id===o.id)
                    hasAlter =true
            })
        })

        console.log("hasAlter : ",hasAlter)
        console.log("isAlterRoot : ",isAlterRoot)

        if(hasAlter){
            if(isAlterRoot)
                set_visible(true)
            else
                set_visible(false)
        }else {
            set_visible(true)
        }

    },[alternatives])

    return(
        <Fragment>
            {visible?
                <>

                    <div className="plan-card-v2">

                        <div className="plan-exercise-card-main-header-alternatives">
                            {myAlters.map((a,index)=>{
                                return(
                                    <div onClick={e=>{
                                        setAlterToRoot(part,a)
                                    }} className={part.id===a.id?"plan-exercise-card-main-header-alternatives-item active-alter clickable":"plan-exercise-card-main-header-alternatives-item clickable"}>
                                        <span>{a.order}</span>
                                    </div>
                                )
                            })}
                        </div>


                        <div className="queue-sec">
                                <div style={selectPartv2.id===part.id?{border:"2px solid #f58f90"}:{}} className={"queue-number"} onClick={e=>{
                                    handleSelectPartV2(part)
                                }}
                                >
                                <span>{part.order}</span>
                            </div>
                        </div>



                        {!part.desc_mode?
                        <div
                            onClick={e=>{
                                console.log(part.id)
                                setInputFocus()
                                set_selectedPart(part.id)
                                handleSelectPartV2(part)
                                // showHourDrawer(part)
                            }}
                            className={selectPartv2===part.id?"card-main-header-v2":"card-main-header-v2"}>


                            <div className="plan-exercise-card-main-header-name" >
                                <span>{part.text}</span>
                                <img style={{margin:'0 0 0 4px'}} src={clockIcon} alt=""/>
                            </div>
                        </div>:null}

                        {part.desc_mode?
                            <div className="meal-desc">
                                <h5>توضیحات : </h5>
                                <span>{part.text}</span>
                            </div>:null}


                        {!part.desc_mode?
                            <div className="plan-exercise-card-main-body">
                                <div className="plan-exercise-card-main-body-childes">
                                    {plan[currentSection]?.times[currentTimes]?.parts.find(p=>p.id===part.id).items.map(i=>{
                                            return(
                                                <div className="plan-exercise-card-main-body-child bullet-sec">
                                                    <span>{i.text}</span>
                                                    <i className="bullet"></i>
                                                </div>
                                            )
                                        }

                                    )}
                                </div>
                            </div>:null}




                    </div>

                    {/*<div className="plan-card-diet">*/}
                    {/*    <div className="plan-exercise-card-main">*/}
                    {/*        <div className="plan-exercise-card-main-header-alternatives">*/}
                    {/*            {myAlters.map((a,index)=>{*/}
                    {/*                return(*/}
                    {/*                    <div onClick={e=>{*/}
                    {/*                        setAlterToRoot(part,a)*/}
                    {/*                    }} className={part.id===a.id?"plan-exercise-card-main-header-alternatives-item active-alter clickable":"plan-exercise-card-main-header-alternatives-item clickable"}>*/}
                    {/*                        <span>{index+1}</span>*/}
                    {/*                    </div>*/}
                    {/*                )*/}
                    {/*            })}*/}
                    {/*        </div>*/}
                    {/*        <div*/}
                    {/*            onClick={e=>{*/}
                    {/*                console.log(part.id)*/}
                    {/*                setInputFocus()*/}
                    {/*                set_selectedPart(part.id)*/}
                    {/*            }}*/}
                    {/*            className={selectedPart===part.id?"plan-exercise-card-main-header active-part":"plan-exercise-card-main-header"}>*/}

                    {/*            <div className="plan-exercise-card-main-header-actions">*/}

                    {/*                <div className="plan-exercise-card-main-header-delete" onClick={e=>{*/}
                    {/*                    handleClickDeletePart(part)*/}
                    {/*                }}>*/}
                    {/*                    <img src={trashIcon} alt=""/>*/}
                    {/*                </div>*/}

                    {/*                {myAlters.length<1?<div className={existInMeals(part)?"plan-exercise-card-main-header-link link-on":"plan-exercise-card-main-header-link"} onClick={e=>{*/}
                    {/*                    addToMeals(part)*/}
                    {/*                }}>*/}
                    {/*                    <LinkOutlined />*/}
                    {/*                </div>:null}*/}


                    {/*                { myAlters.length>1?<div onClick={e=>{*/}
                    {/*                    breakFromLink(part)*/}
                    {/*                }}>*/}
                    {/*                    <CompressOutlined />*/}
                    {/*                </div>:null}*/}

                    {/*            </div>*/}



                    {/*            <div className="plan-exercise-card-main-header-name" onClick={e=>{*/}
                    {/*                showHourDrawer(part)*/}
                    {/*            }}>*/}
                    {/*                <span>{part.text}</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="plan-exercise-card-main-body">*/}
                    {/*            <div className="plan-exercise-card-main-body-childes">*/}
                    {/*                {plan[currentSection]?.times[currentTimes]?.parts[part.id]?.items.map(i=>{*/}
                    {/*                        return(*/}
                    {/*                            <div className="plan-exercise-card-main-body-child">*/}
                    {/*                                <span>{i.text}</span>*/}
                    {/*                                <img onClick={e=>{*/}
                    {/*                                    handleDeletePartItems(part,i)*/}
                    {/*                                }} src={trashWhiteIcon} alt=""/>*/}
                    {/*                            </div>*/}
                    {/*                        )*/}
                    {/*                    }*/}
                    {/*                )}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}

                    {/*    <div className="plan-card-number">*/}
                    {/*        <span>{part.order}</span>*/}

                    {/*    </div>*/}

                    {/*</div>*/}
                </>
                :null}
        </Fragment>
    )
}

function ExPartCard({part,
                    handleClickDeletePart,
                        showSetDrawer,
                        showRepeatDrawer,
                        showRestDrawer,
                        handleSelectPartV2,
                        selectPartv2,
                        addVideo,
                        removeVideoFromPart,
                        plan,
                        sus,
                        set_sus,
                        links,
                        alternatives,
                        supers,
                        set_links,
                        currentTimes,
                        currentSection,
                        setAlterToRoot,
                        breakFromLink,
                        i,
                        uploadVideo
                    }){



    const [thumb,set_thumb] = useState(part.thumb)
    const [video,set_video] = useState(Setup.filePoint+part.video)
    const [partImage, set_partImage] = useState("")
    const [imageFile,set_imageFile] = useState(null)
    const fileRef = useRef(null);
    const [imagePrev,set_imagePrev] = useState(null);

    const [uploadMode,set_uploadMode] = useState("default") // default , video ,upload , pending

    const addToLink = (p)=>{

        let last = links[links.length-1]
        if(last){
            if(p.order-last.order>1)
                return console.log("order error")

            if(p.order<last.order)
                return console.log("order error")

        }

        let find =  links.find(l=>l.id===p.id)
        if(find)
            set_links(links.filter(l=>l.id!==p.id))
            else
        set_links([...links,p])
    }

    const addToSus = (p,alters=[])=>{
        if(alters.length<1){
            let last = sus[sus.length-1]
            if(last){
                if(p.order-last.order>1)
                    return console.log("order error")

                if(p.order<last.order)
                    return console.log("order error")

            }

            let find =  sus.find(l=>l.id===p.id)
            if(find){
                set_sus(sus.filter(l=>l.order<p.order))
            }
            else
                set_sus([...sus,p])
        }else {
            //check order

            let last = sus[sus.length-1]
            if(last){
                if(alters[0].order-last.order>1)
                    return console.log("order error")

                if(alters[0].order<last.order)
                    return console.log("order error")

            }

            let find =  sus.find(l=>l.id===alters[0].id)
            if(find){
                set_sus(sus.filter(l=>l.order<alters[0].order))
            }else
            set_sus([...sus,...alters])
        }

    }


    const existInLinks = (p)=>{
        return links.find(l=>l.id===p.id)
    }
    const existInSus = (p)=>{
        return sus.find(l=>l.id===p.id)
    }

    const [myAlters,set_myAlters] = useState([])

    const [visible,set_visible] = useState(false)
    const [isSuper,set_isSuper] = useState(false)
    const [isSuperRoot,set_isSuperRoot] = useState(false)


    const isSuperCalc = (p)=>{
        let isSu = false
        supers.forEach(a=>{
            a.others.forEach(o=>{
                if(p.id===o.id){
                    isSu =true
                }
            })
        })

        return isSu
    }


    useEffect(()=>{
        console.log("suuuuuu 777777")
        let isSu = false
        supers.forEach(a=>{
            a.others.forEach(o=>{
                if(part.id===o.id){
                    isSu =true
                }
            })
        })

        if(isSu)
            set_isSuper(true)
        else
            set_isSuper(false)

    },[supers])


    useEffect(()=>{
        let hasAlter = false
        let isAlterRoot = false
        set_myAlters([])
        set_visible(false)

        alternatives.forEach(a=>{
            if(part.id===a.root.id){
                isAlterRoot =true;
                set_myAlters(a.others)
            }
            a.others.forEach(o=>{
                if(part.id===o.id)
                    hasAlter =true
            })
        })

        if(hasAlter){
            if(isAlterRoot)
                set_visible(true)
            else
                set_visible(false)
        }else {
            set_visible(true)
        }

    },[alternatives])


    useEffect(()=>{
       if(part?.video?.length > 0){
           set_uploadMode("video")
       }else{
           set_uploadMode("choose")
       }
    },[])

    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const handleImageChange = (e)=>{
        if(e.target.files && e.target.files[0]){
             set_uploadMode("upload")
            set_imageFile(e.target.files[0])
        }
    }

    const handleClickChoose = (num)=>{
        fileRef.current.click();
    }

    const playVideo = ()=>{
        console.log(Setup.filePoint+part?.video?.video)
        set_video(Setup.filePoint+part?.video?.video)
        set_partImage(Setup.filePoint + part?.video?.image)
        showModal()
    }

    const upload = async (title)=>{
        set_uploadMode("pending")
        if(imageFile){
            try{
                let response = await videoUploadApi(imageFile,title,"");
                set_imagePrev(null)
                set_thumb(Setup.filePoint+response.data.data.video)
                set_video(Setup.filePoint+response.data.data.video)
                addVideo(response.data.data.video)
                set_uploadMode("video")
            }catch(e){
                console.log(e)
            }
        }

    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (url) => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const MyRef = useRef(null);

    const handleClickOnVideo = ()=>{
        showVideoDrawer()
    }

    const [visibleVideoDrawer,set_visibleVideoDrawer] = useState(false)
    const onVideoDrawerClose = ()=>{
        set_visibleVideoDrawer(false)
    }
    const showVideoDrawer = ()=>{
        set_visibleVideoDrawer(true)
    }

    const handleSelectVideo =(v)=>{
        console.log(v)
        addVideo(part,v)
        onVideoDrawerClose()
    }


    const confirm = async(p) => {
        removeVideoFromPart(p)
    };
    const cancel = (e) => {
        console.log(e);
    };

    return(
        <Fragment>
            {visible?
                <>

                    <div className="plan-card-v2">

                        <div className="plan-exercise-card-main-header-alternatives">
                            {myAlters.map((a,index)=>{
                                return(
                                    <div onClick={e=>{
                                        setAlterToRoot(part,a)
                                    }} className={part.id===a.id?"plan-exercise-card-main-header-alternatives-item active-alter clickable":"plan-exercise-card-main-header-alternatives-item clickable"}>
                                        <span>{a.order}</span>
                                    </div>
                                )
                            })}
                        </div>

                        <div className={"queue-sec"}>
                            <div style={selectPartv2.id===part.id?{border:"2px solid #f58f90"}:{}} className={isSuper?"queue-number-super":"queue-number"} onClick={e=>{
                                handleSelectPartV2(part)
                            }}
                            >
                                <span>{part.order}</span>
                                {plan[currentSection]?.times[currentTimes]?.parts[i+1]
                                    ?
                                    <div className={isSuper && isSuperCalc(plan[currentSection]?.times[currentTimes]?.parts[i+1])?"plan-card-number-line-super":"plan-card-number-line"}></div>
                                    :null}
                            </div>
                        </div>



                        <div className="plan-card-v2-body">
                            {!part.desc_mode ?

                                <div className="plan-card-v2-body-info">
                                    <h6>{part.text}</h6>
                                    {part?.video?.fa_names?.length>0?
                                        <span>این حرکت دارای تصویر میباشد</span>:
                                        <span>بدون تصویر</span>
                                    }
                                </div>:null}
                            {part.desc_mode ?

                                <div className="plan-card-v2-body-info">
                                    <h6>توضیحات :</h6>
                                    <h3>{part.text}</h3>
                                </div>:null}

                            <div className="plan-card-v2-body-video clickable" onClick={e=>{
                                if(part?.video?.fa_names?.length>0)
                                playVideo()
                                else {
                                    return message.warn({
                                        content: " تصویری موجود نمیباشد",
                                        className: 'toast-class'
                                    });
                                }
                            }}>
                                <div className="plan-card-v2-body-video-card " style={{width:98,flexDirection:"column",border:part?.video?.fa_names?.length>0?'1px solid #03A9F4':'1px solid #B6B6B6'}}>
                                    {part?.video?.fa_names?.length>0?
                                    <img src={addPlayIcon} alt=""/>:
                                    <img src={notPlayIcon} alt=""/>}
                                    {/*<CameraOutlined style={{*/}
                                    {/*    fontSize: 25,*/}
                                    {/*    color: part?.video?.fa_names?.length>0?'#03A9F4':'#b1b0b0'*/}
                                    {/*}} />*/}
                                </div>
                            </div>
                        </div>

                        {!part.desc_mode ?
                            <div className="plan-card-v2-footer">
                                <Swiper ref={MyRef}  {...params}>
                                    <div  className="plan-card-v2-footer-item" onClick={e=>{

                                    }}>
                                        <span>10</span>
                                        <h6>وزن</h6>
                                    </div>
                                    <div  className="plan-card-v2-footer-item" onClick={e=>{
                                        showRestDrawer(part)
                                    }}>
                                        <span>{part.items[3].value}</span>
                                        <h6>استراحت</h6>
                                    </div>
                                    <div  className="plan-card-v2-footer-item" onClick={e=>{
                                        showSetDrawer(part)
                                    }}>
                                        <span>{part.items[2].value}</span>
                                        <h6>تعداد</h6>
                                    </div>
                                    <div  className="plan-card-v2-footer-item" >
                                        <span>10</span>
                                        <h6>مدت</h6>
                                    </div>
                                    <div  className="plan-card-v2-footer-item" onClick={e=>{
                                        showRepeatDrawer(part)
                                    }}>
                                        <span>{part.items[1].value}</span>
                                        <h6>تکرار</h6>
                                    </div>
                                </Swiper>
                            </div>:null}

                    </div>
                        <Drawer    bodyStyle={{padding:0,margin:0}}  placement="top" height={"400"}  closable={false}  onClose={handleCancel} visible={isModalOpen}>
                            {/*{isModalOpen?*/}
                            {/*    <ReactPlayer playing={isModalOpen} width={"100%"} url={video} controls />:*/}
                            {/*    null*/}
                            {/*}*/}

                            <div className="top-shower">
                                {isModalOpen && video!=="https://media.step-space.com/undefined"?
                                    <ReactPlayer playing={isModalOpen} width={"100%"} height={"auto"} url={video} controls/>
                                    :null}
                                {isModalOpen && partImage!=="https://media.step-space.com/undefined"?
                                    <img src={partImage} alt=""/>
                                    :null}
                            </div>
                        </Drawer>

                </>
                :null
            }
        </Fragment>
    )
}

function existInSelectedCopyTo(list,item){
    return list.find(l=>l.index===item.index)
}


const DeletableCardComp = ({children,item,deleteMedia})=>{

    const [deleteStatus,set_deleteStatus] = useState(false)
    const errorDispatch = useDispatchError()

    const onLongPress = () => {
        set_deleteStatus(true)
        console.log("ssssssssssssssssss")
    };
    const onClick = () => {
        console.log("cccccccc")
    }

    const defaultOptions = {
        shouldPreventDefault: false,
        delay: 1000,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    return(
        <div className="deletable-comp" {...longPressEvent}>
            {children}
        </div>
    )
}


function DumbbellPlanCreateTrainee(){

    const MyRef = useRef(null);
    const {planId,traineeId} = useParams()
    // const [message, setMessage] = useState('')
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        browserSupportsContinuousListening
    } = useSpeechRecognition();


    useEffect(()=>{
        set_searchWord(transcript)
    },[transcript])







    //Profile Trainee Drawer
    const [visibleProfileTraineeDrawer,set_visibleProfileTraineeDrawer] = useState(false)

    const showProfileDrawer = ()=>{
        set_visibleProfileTraineeDrawer(!visibleProfileTraineeDrawer)
    }

    const onProfileDrawerClose = () => {
        set_visibleProfileTraineeDrawer(false);
    };


    //Chat Trainee Drawer
    const [visibleChatTraineeDrawer,set_visibleChatTraineeDrawer] = useState(false)

    const showChatDrawer = ()=>{
        set_visibleChatTraineeDrawer(!visibleChatTraineeDrawer)
    }

    const onChatDrawerClose = () => {
        set_visibleChatTraineeDrawer(false);
    };



    //Chat Trainee Drawer
    const [visibleBoxTraineeDrawer,set_visibleBoxTraineeDrawer] = useState(false)

    const showBoxDrawer = ()=>{
        set_visibleBoxTraineeDrawer(!visibleBoxTraineeDrawer)
    }

    const onBoxDrawerClose = () => {
        set_visibleBoxTraineeDrawer(false);
    };

    const breakSuperSet = (p)=>{
        let clone = {...plan}
        let supers = clone[currentSection].times[currentTimes]['supers']
        let cop = [...supers]
        cop = cop.filter(c=>c.root.id!==p.id)
        clone[currentSection].times[currentTimes]['supers'] = cop
        set_plan({})
        set_plan(clone)
        set_links([])
    }

    const changeSuperItems = (part,type,val)=>{
        console.log("ssssss",type,val)
        console.log(currentPart)
        console.log(currentItems)
        let clone = {...plan}
        let supers = clone[currentSection].times[currentTimes].supers;
        supers.forEach(s=>{
            s.others.forEach(o=>{
                if(o.id===part.id){
                        s.items.forEach(i=>{
                            if(i.label===type)
                                i.value=val
                        })
                }
            })
        })
        set_plan(clone)
    }

    const checkSuperRoot = (part,supers)=>{
        let isSuperRoot = false
        supers.forEach(a=>{
            if(part.id===a.root.id){
                isSuperRoot= true
            }
        })

        return isSuperRoot
    }

    const [startAt,set_startAt] = useState("")
    const [basePlan,set_basePlan] = useState({})
    const [plan,set_plan] = useState(
        {
            supplement:{
                type:"week", // week,day
                times:[]
            },
            diet:{
                type:"week", // week,day
                times:[]
            },
            exercise:{
                type:"week", // week,day
                times:[]
            }
        }
    )


    const [my, set_my] = useState({})
    const [token, set_token] = useState(null)
    useEffect(()=>{
        let t = localStorage.getItem("token")
        if(t){
            set_token(t)
            getPlan()
            getMyProfile()
        }else {
            getGuestPlan()
        }
    },[])

    const getGuestPlan = async ()=>{
        try{
            const response = await myGuestPlanOneApi(planId)
            set_planIsEnd(checkPlanEndDate(response.data.data))
            getTrainee(response.data.data)
            let p = response.data.data;
            let diet = p.data.diet.times
            let exercise = p.data.exercise.times
            let supplement = p.data.supplement.times
            set_basePlan(p)
            set_planMode(p.mode)
            set_plan({
                supplement:{
                    times:supplement.length<1?generateTimes(p):supplement
                },
                diet:{
                    times:diet.length<1?generateTimes(p):diet
                },
                exercise:{
                    times:exercise.length<1?generateTimes(p):exercise
                }
            })



            if(p.startAt){
                set_startLabel(p.startAt)
            }else{
                initStartDate()
            }
        }catch(e){
            console.log(e)
        }
    }

    const getMyProfile = async ()=>{
        try{
            const res = await getMyProfileApi()
            set_my(res.data.data)
        }catch (e) {
            console.log(e)
        }
    }

    const [planMode,set_planMode] = useState("")

    const handleChangePlanMode = async (m)=>{
        set_planMode(m)
        try{
            const resp = await changePlanModeApi(basePlan._id,m)
        }catch (e) {
            console.log(e)
        }
    }

    const [unread,set_unread] = useState(0)
    const [trainee,set_trainee] = useState(null)
    const getTrainee = async (p)=>{
        try{
            const res = await getTraineeApi(p.trainee_id)
            set_trainee(res.data.data.trainee)
            set_unread(res.data.data.unread)
        }catch (e) {
            console.log(e)
        }
    }

    const [firstInit,set_firstInit] = useState(false)
    const [plan_ground,set_plan_ground] = useState(true)
    useEffect(()=>{
         console.log("aaaaaaaaaaaaa :",plan[currentSection])
        // if(deleteFlag){
        //     set_plan_ground(false)
        //     setTimeout(()=>{
        //         set_plan_ground(true)
        //     },0)
        //     deleteFlag =false
        // }
        //
        if(!firstInit && plan[currentSection].times.length>0){
            console.log("First Init ",plan[currentSection])
            handleClickOnTimes(plan[currentSection].times[0])
            set_firstInit(true)
        }
    },[plan])

    const [planIsEnd,set_planIsEnd] = useState(false);

    const getPlan = async ()=>{
        try{
            const response = await myPlanOneApi(planId)
            set_planIsEnd(checkPlanEndDate(response.data.data))
            getTrainee(response.data.data)
            let p = response.data.data;
            let diet = p.data.diet.times
            let exercise = p.data.exercise.times
            let supplement = p.data.supplement.times
            set_basePlan(p)
            set_planMode(p.mode)
            set_plan({
                supplement:{
                    times:supplement.length<1?generateTimes(p):supplement
                },
                diet:{
                    times:diet.length<1?generateTimes(p):diet
                },
                exercise:{
                    times:exercise.length<1?generateTimes(p):exercise
                }
            })



            if(p.startAt){
                set_startLabel(p.startAt)
            }else{
                initStartDate()
            }
        }catch(e){
            console.log(e)
        }
    }

    const generateTimes = (p)=>{
        console.log("Generating exercise")
        let start = new Date()
        let results =[]
        for (let i = 0; i < p.duration; i++) {
            let end = start.addDays(i)
            let jDate = moment(end).format('jYYYY/jMM/jDD')
            let jDay = moment(end).format('jDD')
            let jMonth = moment(end).format('jMM')
            let jYear = moment(end).format('jYYYY')
            let weekDay = convertDayOfWeek(moment(end).day())
            results.push({index:i,date:end,jDate:jDate,jDay:+jDay,jMonth:+jMonth,jYear:jYear,weekDay:weekDay,alternatives:[],supers:[],parts:[]})
        }

        return results

    }

    //re generate start Date
    const[reStartDay,set_reStartDay] = useState('0')
    const[reStartMonth,set_reStartMonth] = useState('0')
    const[reStartYear,set_reStartYear] = useState('0')

    const initStartDate =()=>{
        let d = moment(new Date()).format('jDD')
        let m = moment(new Date()).format('jMM')
        let y = moment(new Date()).format('jYYYY')
        console.log(d,m,y)
        set_reStartDay(d)
        set_reStartMonth(m)
        set_reStartYear(y)
        set_startLabel(`${y}/${m}/${d}`)
    }


    const reTimes = (start,duration)=>{
        let results =[]
        for (let i = 0; i < duration; i++) {
            let end = start.addDays(i)
            let jDate = moment(end).format('jYYYY/jMM/jDD')
            let jDay = moment(end).format('jDD')
            let jMonth = moment(end).format('jMM')
            let jYear = moment(end).format('jYYYY')
            let weekDay = convertDayOfWeek(moment(end).day())
            results.push({index:i,date:end,jDate:jDate,jDay:+jDay,jMonth:+jMonth,jYear:jYear,weekDay:weekDay,parts:[]})
        }
        return results
    }


    const [startLabel,set_startLabel] = useState("")
    const reGenerateTimes = ()=>{
        let duration = basePlan.duration
        let jFormat = reStartYear+"/"+reStartMonth+"/"+reStartDay
        let start = moment(jFormat, 'jYYYY/jM/jD').toDate()



        let copy = {...plan}
        copy['supplement'].times = reTimes(start,duration)
        copy['diet'].times = reTimes(start,duration)
        copy['exercise'].times = reTimes(start,duration)
        set_plan(copy)
        set_startLabel(jFormat)
        onStartDrawerClose()
    }

    const [currentSection,set_currentSection]= useState("exercise") //supplement, diet ,exercise
    const [currentTimes,set_currentTimes]= useState("")
    const [selectTimes,set_selectTimes]= useState({})

    const setWeekTemplate = (section)=>{
        let obj = [
            {index:0,label:"شنبه", parts:[]},
            {index:1,label:"یکشنبه", parts:[]},
            {index:2,label:"دوشنبه", parts:[
                    {
                        video:"",
                        text:"",
                        order:1,
                        items:[
                            {type:"exercise",label:"duration",value:5},
                            {type:"exercise",label:"repeat",value:10},
                            {type:"exercise",label:"set",value:4},
                            {type:"exercise",label:"rest",value:60},
                        ]
                    },
                    {
                        video:"",
                        text:"",
                        order:2,
                        items:[
                            {type:"exercise",label:"duration",value:5},
                            {type:"exercise",label:"repeat",value:10},
                            {type:"exercise",label:"set",value:4},
                            {type:"exercise",label:"rest",value:60},
                        ]
                    }
                ]},
            {index:3,label:"سشنبه", parts:[]},
            {index:4,label:"چهارشنبه", parts:[]},
            {index:5,label:"پنجشنبه", parts:[
            {
                video:"",
                text:"",
                order:1,
                items:[
                    {type:"exercise",label:"duration",value:5},
                    {type:"exercise",label:"repeat",value:10},
                    {type:"exercise",label:"set",value:4},
                    {type:"exercise",label:"rest",value:60},
                ]
            }
        ]},
            {index:6,label:"جمعه", parts:[]},
        ]
        let copyOfPlan = {...plan}
        copyOfPlan[section] = {type:"week",times:obj}
        set_plan(copyOfPlan);
        set_swiperReset(false)
        setTimeout(()=>{
            set_swiperReset(true)
        },0)
    }

    const [swiperReset,set_swiperReset] = useState(true)
    const setDayTemplate = (section,startTime,endTime)=>{
        let obj = [
            {index:0,label:"1 دی", parts:[]},
            {index:1,day:"1",weekDay:"شنبه", parts:[]},
            {index:2,day:"2",weekDay:"یکشنبه", parts:[]},
            {index:3,day:"3",weekDay:"دوشنبه", parts:[]},
            {index:4,day:"4",weekDay:"سشنبه", parts:[]},
            {index:5,day:"6",weekDay:"چهارشنبه", parts:[]},
        ]
        let copyOfPlan = {...plan}
        copyOfPlan[section] = {type:"week",times:obj}
        set_plan(copyOfPlan)
        set_swiperReset(false)
        setTimeout(()=>{
            set_swiperReset(true)
        },0)
    }

    const convertDayOfWeek = (num)=>{
        console.log("num",num)
        switch(num){
            case 1:
                return "دوشنبه"
                break;
            case 2:
                return "سشنبه"
                break;
            case 3:
                return "چهارشنبه"
                break;
            case 4:
                return "پنجشنبه"
                break;
            case 5:
                return "جمعه"
                break;
            case 6:
                return "شنبه"
                break;
            case 0:
                return "یکشنبه"
                break;


        }
    }

    // const generateTimes = (start)=>{
    //     let results = [
    //         {day:"1",weekday:"شنبه"},
    //         {day:"2",weekday:"شنبه"}
    //     ]
    //     let startDate = start;
    //     let date = new Date()
    //     let end = date.addDays(58)
    //     let gg = moment(end).format('jYYYY/jMM/jDD')
    //     console.log("END : ",convertDayOfWeek(moment(end).day()))
    //     console.log("END : ",gg)
    // }
    const setTemplate = (section,startTime,endTime)=>{
        let objExer = [
            {index:0,day:"1",weekDay:"جمعه",parts:[]},
            {index:1,day:"2",weekDay:"شنبه", parts:[]},
            {index:2,day:"3",weekDay:"یکشنبه", parts:[]},
            {index:3,day:"4",weekDay:"دوشنبه", parts:[]},
            {index:4,day:"5",weekDay:"سشنبه", parts:[]},
            {index:5,day:"6",weekDay:"چهارشنبه", parts:[]},
        ]

        let objSupp = [
            {index:0,day:"1",weekDay:"جمعه",parts:[]},
            {index:1,day:"2",weekDay:"شنبه", parts:[]},
            {index:2,day:"3",weekDay:"یکشنبه", parts:[]},
            {index:3,day:"4",weekDay:"دوشنبه", parts:[]},
            {index:4,day:"5",weekDay:"سشنبه", parts:[]},
            {index:5,day:"6",weekDay:"چهارشنبه", parts:[]},
        ]

        let objDiet = [
            {index:0,day:"1",weekDay:"جمعه",parts:[]},
            {index:1,day:"2",weekDay:"شنبه", parts:[]},
            {index:2,day:"3",weekDay:"یکشنبه", parts:[]},
            {index:3,day:"4",weekDay:"دوشنبه", parts:[]},
            {index:4,day:"5",weekDay:"سشنبه", parts:[]},
            {index:5,day:"6",weekDay:"چهارشنبه", parts:[]},
        ]

        let copyOfPlan = {...plan}
        copyOfPlan["exercise"] = {type:"week",times:objExer}
        copyOfPlan["diet"] = {type:"week",times:objDiet}
        copyOfPlan["supplement"] = {type:"week",times:objSupp}
        set_plan(copyOfPlan)
        set_swiperReset(false)
        setTimeout(()=>{
            set_swiperReset(true)
        },0)
    }


    const handleChangeSectionType = (e)=>{
        if(e.target.value==="week")
            setWeekTemplate(currentSection)
        if(e.target.value==="day")
            setDayTemplate(currentSection)


    }

    const handleClickOnTimes =(t)=>{
        set_currentTimes(t.index)
        set_selectTimes(t)



    }

    const [listBottom,set_listBottom] = useState(-322)
    const [searchFilter,set_searchFilter] = useState("all") // all , me,public

    const [searchSpin,set_searchSpin] = useState(false)
    const [searchResults,set_searchResults] = useState([])
    const [searchWord,set_searchWord] = useState([])

    const handleClickOnFilter = (type)=>{
        globalFilter = type
        set_searchFilter(type)
        handleSearch(searchWord)
    }

    const [mealInput,set_mealInput] = useState("")

    const handleClickOnItem = (item)=>{
        console.log(item)
        console.log(plan)
        console.log(currentTimes)
        set_listBottom(-322);

        let copyOfPlan = {...plan}
        let parts = copyOfPlan[currentSection].times[currentTimes].parts
        let obj = {
            video:item.video,
            thumb:item.image,
            id:uuidv4(),
            text:item.fa_names[0],
            order:parts.length+1,
            items:[
                {type:"exercise",label:"duration",value:5},
                {type:"exercise",label:"repeat",value:10},
                {type:"exercise",label:"set",value:4},
                {type:"exercise",label:"rest",value:60},
            ]
        }
        copyOfPlan[currentSection] = copyOfPlan[currentSection].times[currentTimes].parts = [...parts,obj]
        set_searchWord("")

        // console.log(copyOfPlan)
         // set_plan(copyOfPlan)
    }
    const [searchRef, setSearchFocus] = useFocus()

    const handleAddNewExercise = ()=>{
        setTimeout(()=>{
            console.log("ssssssssssss")
            window.scrollTo(0, document.body.scrollHeight);
        },1000)


        set_listBottom(-322);
        let copyOfPlan = {...plan}
        let parts = copyOfPlan[currentSection].times[currentTimes].parts
        let obj = {
            video:"",
            thumb:"",
            id:uuidv4(),
            text:searchWord,
            order:parts.length+1,
            items:[
                {type:"exercise",label:"duration",value:5},
                {type:"exercise",label:"repeat",value:10},
                {type:"exercise",label:"set",value:4},
                {type:"exercise",label:"rest",value:60},
            ]
        }
        copyOfPlan[currentSection] = copyOfPlan[currentSection].times[currentTimes].parts = [...parts,obj]
        set_searchWord("")
    }

    const handleSearch = async (text)=>{
        // if(currentTimes.length<1)
        //     return

         set_searchWord(text)
        // set_listBottom(178);
        // set_searchSpin(true)
        // try{
        //     let resp = await searchVideo_api(text,globalFilter)
        //     set_searchResults(resp.data.data);
        //     set_searchSpin(false)
        // }catch (e) {
        //     console.log(e)
        // }

    }


    const handleDeletePartItems = (part,items)=>{
        let clone = {...plan}
        clone[currentSection].times[currentTimes].parts.find(p=>p.id===part.id).items = clone[currentSection].times[currentTimes].parts.find(p=>p.id===part.id).items.filter(i=>i.id!==items.id)
        set_plan(clone)
    }

    const uploadVideo =()=>{

    }


    const suppAlternativeSet = ()=>{
        let clone = {...plan}
        let firstLink = supp[0]
        let alters = clone[currentSection].times[currentTimes]['alternatives']
        let obj = {root:firstLink,others:supp}
        alters = [...alters,obj]
        clone[currentSection].times[currentTimes]['alternatives'] = alters
        set_plan({})

        // reorder(clone)

        set_plan(clone)
        set_supp([])
    }


    const mealAlternativeSet = ()=>{
        let clone = {...plan}
        let firstLink = meals[0]
        let alters = clone[currentSection].times[currentTimes]['alternatives']
        let obj = {root:firstLink,others:meals}
        alters = [...alters,obj]
        clone[currentSection].times[currentTimes]['alternatives'] = alters
        set_plan({})

        // reorder(clone)

        set_plan(clone)
        set_meals([])
    }

    const alternativeSet = ()=>{
        let clone = {...plan}
        let firstLink = links[0]
        let alters = clone[currentSection].times[currentTimes]['alternatives']
        let obj = {root:firstLink,others:links}
        alters = [...alters,obj]
        clone[currentSection].times[currentTimes]['alternatives'] = alters
        set_plan({})

        // reorder(clone)

        set_plan(clone)
        set_links([])
    }

    const superSet = ()=>{
        console.log(plan)
        console.log(sus)
        let items = [
        {type: 'exercise', label: 'duration', value: 5},
        {type: 'exercise', label: 'repeat', value: 4},
        {type: 'exercise', label: 'set', value: 1},
        {type: 'exercise', label: 'rest', value: 15}
        ]
        let clone = {...plan}
        let firstLink = sus[0]
        let supers = clone[currentSection].times[currentTimes]['supers']
        let obj = {root:firstLink,others:sus,items:items}
        supers = [...supers,obj]
        clone[currentSection].times[currentTimes]['supers'] = supers
        set_plan({})
        set_plan(clone)
        set_sus([])

    }


    //sup
    const [selectSupPartv2,set_selectSupPartv2] = useState(-1)
    const [selectPartType,set_selectPartType] = useState(null)
    const handleSelectSupPartV2 = (part)=>{
        set_selectPartType("sup")
        set_selectSupPartv2(part)
    }

    //meal
    const [selectMealPartv2,set_selectMealPartv2] = useState(-1)
    const handleSelectMealPartV2 = (part)=>{
        console.log("meal part : ",part)
        set_selectPartType("meal")
        set_selectMealPartv2(part)
    }

    //ex
    const [selectPartv2,set_selectPartv2] = useState(-1)
    const handleSelectPartV2 = (part)=>{
        console.log("part : ",part)
        set_selectPartType("ex")
        set_selectPartv2(part)
    }

    const breakFromLink = (p)=>{
        let clone = {...plan}
        let alters = clone[currentSection].times[currentTimes]['alternatives']
        let cop = [...alters]
        let breakAll =false
        // cop.forEach(a=>{
        //     if(a.root.id===p.id){
        //         if(a.others.length>2){
        //             let oth = a.others.filter(o=>o.id!==p.id)
        //             a.root = oth[0]
        //             a.others = oth
        //         }
        //         if(a.others.length===2){
        //             breakAll =true
        //         }
        //     }
        // })
        //
        // if(breakAll)
        cop = cop.filter(c=>c.root.id!==p.id)

        clone[currentSection].times[currentTimes]['alternatives'] = cop
        set_plan({})
        set_plan(clone)
        // set_links([])
    }

    const reorder = (p)=>{
        let times = p[currentSection].times[currentTimes]
        let result = []
        let count = 0
        times.parts.forEach(part=>{
            count = count+1
            part.order = count
            times.alternatives.forEach(a=>{
                if(a.root.id===part.id){
                    a.others.forEach(o=>{
                        o.order = count
                    })
                }
            })

        })

        console.log("plan : ",p)
    }

    const setAlterToRoot = (oldRoot,newAlterRoot)=>{
        let clone = {...plan}
        let alters = clone[currentSection].times[currentTimes]['alternatives']
        let cop = [...alters]
        cop.forEach(a=>{
            if(a.root.id===oldRoot.id){
                a.root = newAlterRoot
            }
            a.others.forEach(o=>{
                if(o.id===oldRoot.id){
                    o = oldRoot
                }
            })
        })
        clone[currentSection].times[currentTimes]['alternatives'] = cop
        set_plan({})
        //reorder

        set_plan(clone)
        // set_links([])
    }

    const [meals,set_meals] = useState([])
    const [supp,set_supp] = useState([])

    const [links,set_links] = useState([])
    const [sus,set_sus] = useState([])
    useEffect(()=>{
        console.log("links : ",links)
    },[links])
    const handleClickDeletePart = (p)=>{
        let clone = {...plan}
        let re = clone[currentSection].times[currentTimes].parts.filter(i=>i.id!==p.id)
        //     .map((m,index)=>{
        //     return {...m,order:index+1}
        // })
        console.log("ress  ",currentSection,currentTimes,re)

        clone[currentSection].times[currentTimes].parts = re
        // deleteFlag=true
         set_plan(clone)
        //
        let find =  links.find(l=>l.id===p.id)
        if(find)
            set_links(links.filter(l=>l.id!==p.id))
    }

    //start drawer
    const [visibleStartDrawer,set_visibleStartDrawer] = useState(false)
    const onStartDrawerClose = ()=>{
        set_visibleStartDrawer(false)
    }
    const showStartDrawer = (p)=>{
        if(basePlan.deliveredAt && basePlan.deliveredAt.length>0){
            return message.warn({
                content: "بعد از تحویل قادر به تغییر شروع دوره نمیباشید",
                className: 'toast-class'
            });
        }else {
            set_visibleStartDrawer(true)
        }

    }


    const handleChangeWeekMode = ()=>{
        handleChangePlanMode(planMode==="days"?"week":"days")
    }

    //rest drawer
    const [visibleRestDrawer,set_visibleRestDrawer] = useState(false)
    const onRestDrawerClose = ()=>{
        set_visibleRestDrawer(false)
    }
    const showRestDrawer = (p)=>{
        set_visibleRestDrawer(true)
        set_currentPart(p)
        set_currentItems("rest")
    }

    //save drawer
    const [visibleSaveDrawer,set_visibleSaveDrawer] = useState(false)
    const onSaveDrawerClose = ()=>{
        set_visibleSaveDrawer(false)
    }
    const showSaveDrawer = (p)=>{
        console.log("plan : ",plan)
        set_visibleSaveDrawer(true)
    }

    //save
    const [saveToLibrary,set_saveToLibrary] = useState(false)
    const [libraryNameInput,set_libraryNameInput] = useState("")











    const [currentItems,set_currentItems] = useState(null)
    const [currentPart,set_currentPart] = useState(null)


    const  handleSelectItems = (type,num)=>{
        console.log(currentPart)
        console.log(currentItems)
        let clone = {...plan}
        let parts = clone[currentSection].times[currentTimes].parts;
        parts.forEach(p=>{
            if(p.id===currentPart.id){
                p.items.forEach(i=>{
                    if(i.label===type)
                        i.value=num
                })
            }
        })
        set_plan(clone)

    }

    const [selectedPart,set_selectedPart] = useState("")

    //diet

    const [inputRef, setInputFocus] = useFocus()




    return(
        <div>
            <div className="fix-content">


                <br/>
                {/*<DumbbellHederTrainee title={"برنامه"} back={true}/>*/}


                <div className="plan-add-header-v2-package">
                    {my?.first_name?
                        <div className="plan-add-header-info">
                            <span className="name">{my?.first_name+" "+my?.last_name}</span>
                            <span className="mobile">{my?.mobile}</span>
                            <span className="package-name">{`نام پکیج : ${basePlan?.title}`}</span>
                            <span className="package-trainer-name">{`تنظیم شده توسط : ${basePlan?.trainer_first_name+" "+basePlan?.trainer_last_name}`}</span>

                        </div>:null
                    }
                    <div className="plan-add-header-image-package " >
                        {basePlan?.trainer?.image?<img src={Setup.filePoint+basePlan?.trainer?.image} alt=""/>:<img src={sampleFace} alt=""/>}
                    </div>
                </div>

            </div>

            <div className="actions-v2">
                <div className="dumbbell-trainee-card-message" onClick={e=>{
                    if (currentSection === "exercise")
                        window.open(`/trainee/printable/${basePlan._id}`, '_blank');

                    if (currentSection === "diet")
                        window.open(`/trainee/printable-diet/${basePlan._id}`, '_blank');

                    if (currentSection === "supplement")
                        window.open(`/trainee/printable-supplement/${basePlan._id}`, '_blank');

                }}>
                    <img src={printIcon} alt=""/>
                </div>

                <div className="dumbbell-header-plan-card-actions actions-margin">

                    {basePlan.chat_option?
                        <div className="dumbbell-trainee-card-message" onClick={e=>{
                            showChatDrawer()
                        }}>
                            <img src={messageIcon} alt=""/>
                            {unread>0? <span className="dumbbell-trainee-card-message-badge">{unread}</span>:null}
                        </div>:null
                    }

                    {basePlan.box_option?
                        <div className="dumbbell-trainee-card-box" onClick={e=>{
                            showBoxDrawer()
                        }}>
                            <img src={picIcon} alt=""/>
                        </div>:null
                    }


                    <div className="dumbbell-trainee-card-box" onClick={e=>{
                        showProfileDrawer()
                    }}>
                        {/*<img src={profileIcon} alt=""/>*/}
                    </div>
                </div>
            </div>
            <div className="plan-add-tabs">

                <div className="plan-add-tabs-item-v2"
                     onClick={e=>set_currentSection("diet")}
                     style={currentSection==="diet"?{backgroundColor:"#8D89FD"}:{}}>
                    <span style={currentSection==="diet"?{color:"#ffffff"}:{}}>تغذیه</span>
                </div>

                <div className="plan-add-tabs-item-v2 active"
                     onClick={e=>set_currentSection("exercise")}
                     style={currentSection==="exercise"?{backgroundColor:"#8D89FD",color:"white !important"}:{}}>
                    <span style={currentSection==="exercise"?{color:"#ffffff"}:{}}>تمرین</span>
                </div>

                <div className="plan-add-tabs-item-v2"
                     onClick={e=>{
                         console.log(plan)
                         set_currentSection("supplement")
                     }}
                     style={currentSection==="supplement"?{backgroundColor:"#8D89FD",color:"white"}:{}}>
                    <span style={currentSection==="supplement"?{color:"#ffffff"}:{}}>مکمل</span>
                </div>
            </div>



            <div className="plan-sec">
                {plan[currentSection]?.times.length>0?<div className="plan-bar">

                    {swiperReset?<div className="plan-bar-list">
                        <Swiper ref={MyRef}  {...params}>
                            {calcTimes(plan[currentSection].times,planMode).map(time=>{
                                return(
                                    <div  className={'plan-bar-item'}
                                          style={{
                                              color:currentTimes===time.index?"#8D89FD":"",
                                              border:time?.parts?.length>0?"1px dashed #dedede":"",
                                              borderRadius:4
                                          }}
                                          onClick={e=>{
                                        handleClickOnTimes(time)
                                    }}>

                                            {planMode==="days"?<span style={{fontFamily:"YekanRegular"}}>{time.index+1}</span>:null}
                                            <span style={{fontFamily:"YekanBold"}}>{time.weekDay}</span>
                                    </div>
                                )
                            })}

                        </Swiper>
                    </div>:null}


                    <div className="plan-month">
                        {selectTimes.jMonth?<span>{selectTimes.jYear} {convertMonthIntNumberToName(selectTimes.jMonth)}</span>:null}
                    </div>
                </div>:null}


                {plan_ground?<div className="plan-create-list">

                    {plan[currentSection]?.times[currentTimes]?.parts.map((part,i)=>{
                        return(
                            <Fragment>
                                {currentSection==="exercise"?
                                    <>
                                        {checkSuperRoot(part,plan[currentSection]?.times[currentTimes].supers)?
                                        <SuperHeaderCard changeSuperItems={changeSuperItems} breakSuperSet={breakSuperSet} part={part} supers={plan[currentSection]?.times[currentTimes].supers}/>
                                            :null}
                                        <ExPartCard
                                            uploadVideo={uploadVideo}
                                            setAlterToRoot={setAlterToRoot}
                                            breakFromLink={breakFromLink}
                                            alternatives={plan[currentSection]?.times[currentTimes].alternatives}
                                            supers={plan[currentSection]?.times[currentTimes].supers}
                                            part={part}
                                            selectPartv2 ={selectPartv2}
                                            handleSelectPartV2={handleSelectPartV2}
                                            links={links}
                                            set_links={set_links}
                                            sus={sus}
                                            set_sus={set_sus}
                                            handleClickDeletePart={handleClickDeletePart}
                                            showSetDrawer={e=>{}}
                                            showRepeatDrawer={e=>{}}
                                            showRestDrawer={showRestDrawer}
                                            plan={plan}
                                            addVideo={e=>{}}
                                            removeVideoFromPart={e=>{}}
                                            currentSection={currentSection}
                                            currentTimes={currentTimes}
                                            i={i}
                                        />
                                    </>
                                    :null}
                                {currentSection==="diet"?<MealPartCard
                                        currentSection={currentSection}
                                        setAlterToRoot={setAlterToRoot}
                                        alternatives={plan[currentSection]?.times[currentTimes].alternatives}
                                        currentTimes={currentTimes}
                                        handleClickDeletePart={handleClickDeletePart}
                                        handleDeletePartItems={handleDeletePartItems}
                                        meals={meals}
                                        selectPartv2 ={selectMealPartv2}
                                        handleSelectPartV2={handleSelectMealPartV2}
                                        breakFromLink={breakFromLink}
                                        set_meals={set_meals}
                                        part={part}
                                        plan={plan}
                                        selectedPart={selectedPart}
                                        set_selectedPart={set_selectedPart}
                                        setInputFocus={setInputFocus}
                                    />:null
                                }

                                {currentSection==="supplement"?<SuppPartCard
                                    currentSection={currentSection}
                                    setAlterToRoot={setAlterToRoot}
                                    alternatives={plan[currentSection]?.times[currentTimes].alternatives}
                                    currentTimes={currentTimes}
                                    handleClickDeletePart={handleClickDeletePart}
                                    handleDeletePartItems={handleDeletePartItems}
                                    supp={supp}
                                    selectPartv2 ={selectSupPartv2}
                                    handleSelectPartV2={handleSelectSupPartV2}
                                    breakFromLink={breakFromLink}
                                    set_supp={set_supp}
                                    part={part}
                                    plan={plan}
                                    selectedPart={selectedPart}
                                    set_selectedPart={set_selectedPart}
                                    setInputFocus={setInputFocus}
                                />:null
                                }

                            </Fragment>
                        )
                    })}

                </div>:null}


            </div>


            <Drawer push={false}  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onBoxDrawerClose} visible={visibleBoxTraineeDrawer}>
                {visibleBoxTraineeDrawer?<DumbbellBoxTrainee visibleBoxTraineeDrawer={visibleBoxTraineeDrawer} trainee={{_id:basePlan.trainer_id,first_name:basePlan.trainer_first_name,last_name:basePlan.trainer_last_name,mobile:""}} />:null}
            </Drawer>

            <Drawer  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onChatDrawerClose} visible={visibleChatTraineeDrawer}>
                {visibleChatTraineeDrawer?<DumbbellChatTrainee visibleChatTraineeDrawer={visibleChatTraineeDrawer} trainee={{_id:basePlan.trainer_id,first_name:basePlan.trainer_first_name,last_name:basePlan.trainer_last_name,mobile:""}} />:null}
            </Drawer>


            <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onProfileDrawerClose} visible={visibleProfileTraineeDrawer}>
                <DumbbellAddTrainee fill={my} traineeMode={true}/>
            </Drawer>

        </div>
    )
}

export default DumbbellPlanCreateTrainee;
