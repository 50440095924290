import React, { useState } from "react";
import "./Widget.scss";
import PageV2 from "./PageV2";
import SubscriptionWidget from "./SubscriptionWidget";

const Widget = () => {
    return (
        <SubscriptionWidget/>
    );
};

export default Widget;

