import React, {Fragment, useState} from "react";
import {Drawer, Spin} from "antd";
import {StarOutlined} from "@ant-design/icons";
import {Setup} from "../../Services/Setup";
import {numberWithCommas} from "../../Services/utils";
import DumbbellLandingPageSubscriptionDrawer
    from "../DumbbellLandingPageSubscriptionDrawer/DumbbellLandingPageSubscriptionDrawer";

function SubscriptionCard({item,slug,page}){
    const [cardSpin,set_cardSpin] = useState(false)

    //plans drawer
    const [visibleTraineeDrawer,set_visibleTraineeDrawer] = useState(false)

    const showPayDrawer = (p)=>{
        set_visibleTraineeDrawer(true)
    }

    const onPayDrawerClose = () => {
        set_visibleTraineeDrawer(false);
    };

    return(
        <Fragment>
            <Spin spinning={cardSpin}>
                <div className="package-card" style={{margin:"10px 12px"}}>
                    <div className="package-card-image">
                        {item.total_campaign_discount>0?
                            <div className="discount-badge-quick">
                                <span>{`جشنواره`}<StarOutlined /></span>
                            </div>:null
                        }
                        <img src={Setup.filePoint+item.image} alt=""/>
                    </div>
                    <div className="package-card-header subs-card-header">
                        <h6>{item.title}</h6>
                    </div>
                    <div className="package-card-des">
                        {/*{item.descriptions.map(d=>{*/}
                        {/*    return(*/}
                        {/*        <div className="package-card-des-item">*/}
                        {/*            <div className="package-card-des-item-label">*/}
                        {/*                <span>{d.text}</span>*/}
                        {/*            </div>*/}
                        {/*            <div className="package-card-des-item-bullet"></div>*/}
                        {/*        </div>*/}
                        {/*    )*/}
                        {/*})}*/}



                        {item.group_camp?.amount?
                            <div className="group-buy">
                                <span className="label-text">{`اگه ${item.group_camp?.min_person_count} نفری ثبت نام کنید هر نفر ${numberWithCommas(item.group_camp?.amount)} تومان تخفیف میگیره !  `}</span>
                            </div>:null
                        }
                        {item?.description?.map(m=>{
                            return(
                                <div className="package-card-des-item">
                                    <div className="package-card-des-item-label">
                                        <span>{m.text}</span>
                                    </div>
                                    <div className="package-card-des-item-bullet"></div>
                                </div>
                            )
                        })}

                    </div>

                    <div className="package-card-price">

                        <span className="package-card-pay_amount">{numberWithCommas(item.price-item.discount)}تومان </span>
                        {item.discount>0?
                            <span className="package-card-amount">{numberWithCommas(item.price)}تومان </span>:
                            null
                        }
                        <span className="package-card-extra_label">{item.label} </span>
                    </div>

                    <div className="package-card-btn clickable" onClick={e=>{
                        showPayDrawer()
                    }}>
                        <span>گرفتن اشتراک</span>
                    </div>

                </div>
            </Spin>
            <Drawer bodyStyle={{padding:0,margin:0}} destroyOnClose={true}   width={300} closable={false}  onClose={onPayDrawerClose} visible={visibleTraineeDrawer}>
                <DumbbellLandingPageSubscriptionDrawer plan={item} slug={slug} page={page} trainerName={page.full_name} handleDoneAddTrainee={e=>{
                    console.log("done")
                }} />
            </Drawer>
        </Fragment>
    )
}


export default SubscriptionCard;